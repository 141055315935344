// Generated by Framer (c01e615)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["bTwQxasli"];

const variantClassNames = {bTwQxasli: "framer-v-1teo1j8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, placeholder, width, ...props}) => { return {...props, qJ10RJK4K: placeholder ?? props.qJ10RJK4K} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;placeholder?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, qJ10RJK4K, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "bTwQxasli", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-veg6a", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill"}} className={cx("framer-1teo1j8", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"bTwQxasli"} ref={ref} style={{...style}}><Image background={{alt: "", fit: "fill", intrinsicHeight: 16, intrinsicWidth: 16, pixelHeight: 32, pixelWidth: 32, sizes: "16px", ...toResponsiveImage(qJ10RJK4K)}} className={"framer-ejp6j0"} data-framer-name={"Placeholder"} layoutDependency={layoutDependency} layoutId={"Q_NH1244B"}/></Image></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-veg6a [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-veg6a .framer-htiqka { display: block; }", ".framer-veg6a .framer-1teo1j8 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 16px; }", ".framer-veg6a .framer-ejp6j0 { flex: none; height: 16px; overflow: visible; position: relative; width: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-veg6a .framer-1teo1j8 { gap: 0px; } .framer-veg6a .framer-1teo1j8 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-veg6a .framer-1teo1j8 > :first-child { margin-left: 0px; } .framer-veg6a .framer-1teo1j8 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"qJ10RJK4K":"placeholder"}
 */
const FramerolU24rLGk: React.ComponentType<Props> = withCSS(Component, css, "framer-veg6a") as typeof Component;
export default FramerolU24rLGk;

FramerolU24rLGk.displayName = "Placer";

FramerolU24rLGk.defaultProps = {height: 16, width: 16};

addPropertyControls(FramerolU24rLGk, {qJ10RJK4K: {title: "Placeholder", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerolU24rLGk, [])